<template>
  <div>
    <section v-if="!justRegistered">
      <div class="mt-16">
        <h2 class="text-center text-body-4 text-4xl mb-4">
          Přihlášení do aplikace
        </h2>
      </div>

      <div class="max-w-form mt-10 mx-auto mb-3 px-4">
        <label class="text-gray-600 font-bold mb-3">Email*</label>
        <div class="flex items-center mt-2 mb-2">
          <input
            type="text"
            class="pl-3 w-full h-12 border rounded-md text-gray-700 focus:outline-none focus:border-body-4 focus:bg-body-1 focus:bg-opacity-5"
            v-model="userProfile.email"
            @change="validateEmail()"
            :class="[
              (submitted || emailChecked) &&
              (!$v.userProfile.email.required || !$v.userProfile.email.email)
                ? 'border-red-500'
                : '',
            ]"
          />
        </div>
        <div
          v-if="(submitted || emailChecked) && !$v.userProfile.email.required"
          class="font-medium text-sm text-red-500"
        >
          {{ $t("ErrorMessages.FieldIsRequired") }}
        </div>
        <div
          v-if="(submitted || emailChecked) && !$v.userProfile.email.email"
          class="font-medium text-sm text-red-500"
        >
          {{ $t("ErrorMessages.EmailBadFormat") }}
        </div>
      </div>

      <div class="max-w-form mt-10 mx-auto mb-3 px-4">
        <label class="text-gray-600 font-bold">Heslo*</label>
        <div class="flex items-center mt-2 mb-2">
          <input
            type="password"
            class="pl-3 w-full h-12 border rounded-md text-gray-700 focus:outline-none focus:border-body-4 focus:bg-body-1 focus:bg-opacity-5"
            v-model="userProfile.password"
            :class="
              submitted && !$v.userProfile.password.required
                ? 'border-red-500'
                : ''
            "
          />
        </div>
        <div
          v-if="submitted && !$v.userProfile.password.required"
          class="font-medium text-sm text-red-500"
        >
          {{ $t("ErrorMessages.FieldIsRequired") }}
        </div>
      </div>
      <div class="text-center my-10">
        <button
          class="focus:outline-none px-8 font-bold text-white h-16 bg-body-3 hover:opacity-70 duration-200 rounded-md"
          @click="login()"
        >
          Přihlásit se
        </button>
      </div>
      <div class="text-center my-4">
        Nemáte účet?
        <router-link to="/registrace" class="underline text-body-1"
          >Registrujte se zde</router-link
        >
      </div>
      <div
        @click="passwordHandler()"
        class="text-center text-body-1 underline cursor-pointer hover:text-blue-800 pb-16"
      >
        Zapoměli jste heslo?
      </div>
      <div class="w-1/4 mx-auto py-4" v-if="forgotPassword">
        <input
          type="text"
          class="pl-3 w-full h-12 border rounded-md text-gray-700 focus:outline-none focus:border-body-4 focus:bg-body-1 focus:bg-opacity-5"
          v-model="forgotPasswordEmail"
          placeholder="Váš registrovaný email"
          @change="validateEmail()"
          :class="[forgotPasswordErrorMessage ? 'border-red-500' : '']"
        />
        <div
          v-if="forgotPasswordErrorMessage"
          class="font-medium text-sm text-red-500"
        >
          {{ $t(forgotPasswordErrorMessage) }}
        </div>

        <div
          class="text-center"
          v-if="
            $v.forgotPasswordEmail.email &&
            forgotPasswordEmail !== '' &&
            !forgotPasswordEmailSent
          "
        >
          <button
            @click="sendforgotPasswordEmail()"
            class="focus:outline-none px-2 py-2 mt-2 font-bold text-white bg-body-3 hover:opacity-70 duration-200 rounded-md"
          >
            Odeslat
          </button>
        </div>
        <div
          v-if="forgotPasswordEmailSent"
          class="font-medium text-sm text-black-500 text-center mt-3"
        >
          {{ $t("SuccessMessages.ResetPasswordEmailSent") }}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { LOGIN } from "@/store/user/constants";
import { required, email } from "vuelidate/lib/validators";
import { getUserEmail } from "../services/rest/services";
import axios from "axios";

export default {
  data() {
    return {
      userProfile: {},
      emailChecked: false,
      submitted: false,
      forgotPassword: false,
      forgotPasswordEmail: "",
      forgotPasswordEmailSent: false,
      forgotPasswordErrorMessage: "",
      justRegistered: false,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  methods: {
    async sendforgotPasswordEmail() {
      await axios
        .post(`${process.env.VUE_APP_STRAPI_API_URL}/auth/forgot-password`, {
          email: this.forgotPasswordEmail,
        })
        .then((this.forgotPasswordEmailSent = true))
        .catch((error) => {
          this.forgotPasswordErrorMessage =
            error.response.data.data[0].messages[0].id;
        });
    },

    passwordHandler() {
      this.forgotPassword = !this.forgotPassword;
    },
    ...mapActions("user", {
      loginStore: LOGIN,
    }),
    async login() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        const emails = await getUserEmail(this.userProfile.email);
        if (!emails.email) {
          return this.$toast("Váš email není v databázi. Nejdříve se zaregistrujte.", {
            className: ["et-alert, screen-style"],
            horizontalPosition: "center",
            mode: "override",
          });
        }

        await this.loginStore({
          email: this.userProfile.email,
          password: this.userProfile.password,
        });
        this.$store.commit("user/SET_USER", this.user);

        if (this.user.confirmed === true) {
          return this.$router.push("/nemovitost");
        } else if (this.user.confirmed === false) {
          return this.$toast("Váš email není potvrzen. Prosím podívejte se do emailu a potvrďte email.", {
            className: ["et-alert, screen-style"],
            horizontalPosition: "center",
            mode: "override",
          });
        }
      } catch (error) {
        const errorId = error.response.data.message[0].messages[0].id

        if (errorId === 'Auth.form.error.confirmed'){
          this.$toast("Váš email není potvrzen. Prosím podívejte se do emailu a potvrďte email.", {
            className: ["et-alert, screen-style"],
            horizontalPosition: "center",
            mode: "override",
          });
        } else {
  
          this.$toast("Neúspěšné přihlášení. Email nebo heslo není správně.", {
            className: ["et-alert, screen-style"],
            horizontalPosition: "center",
            mode: "override",
          });
        }
      }
    },
    validateEmail() {
      this.emailChecked = true;
      this.forgotPasswordErrorMessage = "";
      this.$v.$touch();
    },
  },
  validations: {
    forgotPasswordEmail: { email },
    userProfile: {
      email: { required, email },
      password: { required },
    },
  },
  mounted() {
    document.addEventListener("keyup", (e) => {
      if (e.keyCode === 13 && this.$router.currentRoute.name === `prihlaseni`) {
        return this.login();
      } else return;
    });
  },
};
</script>

<style>
.screen-style {
  background-color: rgba(220, 38, 38, 0.8);
  position: fixed;
  border-radius: 0;
  padding: 15px;
  min-width: 100%;
  width: 100%;
}
</style>
