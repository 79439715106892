var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.justRegistered)?_c('section',[_vm._m(0),_c('div',{staticClass:"max-w-form mt-10 mx-auto mb-3 px-4"},[_c('label',{staticClass:"text-gray-600 font-bold mb-3"},[_vm._v("Email*")]),_c('div',{staticClass:"flex items-center mt-2 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userProfile.email),expression:"userProfile.email"}],staticClass:"pl-3 w-full h-12 border rounded-md text-gray-700 focus:outline-none focus:border-body-4 focus:bg-body-1 focus:bg-opacity-5",class:[
            (_vm.submitted || _vm.emailChecked) &&
            (!_vm.$v.userProfile.email.required || !_vm.$v.userProfile.email.email)
              ? 'border-red-500'
              : '' ],attrs:{"type":"text"},domProps:{"value":(_vm.userProfile.email)},on:{"change":function($event){return _vm.validateEmail()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userProfile, "email", $event.target.value)}}})]),((_vm.submitted || _vm.emailChecked) && !_vm.$v.userProfile.email.required)?_c('div',{staticClass:"font-medium text-sm text-red-500"},[_vm._v(" "+_vm._s(_vm.$t("ErrorMessages.FieldIsRequired"))+" ")]):_vm._e(),((_vm.submitted || _vm.emailChecked) && !_vm.$v.userProfile.email.email)?_c('div',{staticClass:"font-medium text-sm text-red-500"},[_vm._v(" "+_vm._s(_vm.$t("ErrorMessages.EmailBadFormat"))+" ")]):_vm._e()]),_c('div',{staticClass:"max-w-form mt-10 mx-auto mb-3 px-4"},[_c('label',{staticClass:"text-gray-600 font-bold"},[_vm._v("Heslo*")]),_c('div',{staticClass:"flex items-center mt-2 mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userProfile.password),expression:"userProfile.password"}],staticClass:"pl-3 w-full h-12 border rounded-md text-gray-700 focus:outline-none focus:border-body-4 focus:bg-body-1 focus:bg-opacity-5",class:_vm.submitted && !_vm.$v.userProfile.password.required
              ? 'border-red-500'
              : '',attrs:{"type":"password"},domProps:{"value":(_vm.userProfile.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userProfile, "password", $event.target.value)}}})]),(_vm.submitted && !_vm.$v.userProfile.password.required)?_c('div',{staticClass:"font-medium text-sm text-red-500"},[_vm._v(" "+_vm._s(_vm.$t("ErrorMessages.FieldIsRequired"))+" ")]):_vm._e()]),_c('div',{staticClass:"text-center my-10"},[_c('button',{staticClass:"focus:outline-none px-8 font-bold text-white h-16 bg-body-3 hover:opacity-70 duration-200 rounded-md",on:{"click":function($event){return _vm.login()}}},[_vm._v(" Přihlásit se ")])]),_c('div',{staticClass:"text-center my-4"},[_vm._v(" Nemáte účet? "),_c('router-link',{staticClass:"underline text-body-1",attrs:{"to":"/registrace"}},[_vm._v("Registrujte se zde")])],1),_c('div',{staticClass:"text-center text-body-1 underline cursor-pointer hover:text-blue-800 pb-16",on:{"click":function($event){return _vm.passwordHandler()}}},[_vm._v(" Zapoměli jste heslo? ")]),(_vm.forgotPassword)?_c('div',{staticClass:"w-1/4 mx-auto py-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.forgotPasswordEmail),expression:"forgotPasswordEmail"}],staticClass:"pl-3 w-full h-12 border rounded-md text-gray-700 focus:outline-none focus:border-body-4 focus:bg-body-1 focus:bg-opacity-5",class:[_vm.forgotPasswordErrorMessage ? 'border-red-500' : ''],attrs:{"type":"text","placeholder":"Váš registrovaný email"},domProps:{"value":(_vm.forgotPasswordEmail)},on:{"change":function($event){return _vm.validateEmail()},"input":function($event){if($event.target.composing){ return; }_vm.forgotPasswordEmail=$event.target.value}}}),(_vm.forgotPasswordErrorMessage)?_c('div',{staticClass:"font-medium text-sm text-red-500"},[_vm._v(" "+_vm._s(_vm.$t(_vm.forgotPasswordErrorMessage))+" ")]):_vm._e(),(
          _vm.$v.forgotPasswordEmail.email &&
          _vm.forgotPasswordEmail !== '' &&
          !_vm.forgotPasswordEmailSent
        )?_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"focus:outline-none px-2 py-2 mt-2 font-bold text-white bg-body-3 hover:opacity-70 duration-200 rounded-md",on:{"click":function($event){return _vm.sendforgotPasswordEmail()}}},[_vm._v(" Odeslat ")])]):_vm._e(),(_vm.forgotPasswordEmailSent)?_c('div',{staticClass:"font-medium text-sm text-black-500 text-center mt-3"},[_vm._v(" "+_vm._s(_vm.$t("SuccessMessages.ResetPasswordEmailSent"))+" ")]):_vm._e()]):_vm._e()]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-16"},[_c('h2',{staticClass:"text-center text-body-4 text-4xl mb-4"},[_vm._v(" Přihlášení do aplikace ")])])}]

export { render, staticRenderFns }